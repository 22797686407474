import { Backdrop, Box, Card, Modal, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { RootState } from "../../redux/store";
import {
  LoadingImage,
  clipPageSliceType,
  updateResultImg,
} from "../../redux/clipPageSlice";
import { userInfoServerBaseUrl } from "../../env";
import { nanoid } from "nanoid";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const LoadingOverlay = ({ display }) => {
  if (!display) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
      }}
    >
      <Oval
        visible={true}
        height="45"
        width="45"
        color="#6686af"
        secondaryColor="#6686af"
        ariaLabel="oval-loading"
      />
    </Box>
  );
};

const ResultModal = () => {
  const dispatch = useDispatch();
  const auth = useSelector<any>((state) => state.auth);
  const srcImg = useSelector<RootState, clipPageSliceType["srcImg"]>(
    (state) => state.clipPage.srcImg
  );
  const dstImg = useSelector<RootState, clipPageSliceType["dstImg"]>(
    (state) => state.clipPage.dstImg
  );
  const resultImg = useSelector<RootState, clipPageSliceType["resultImg"]>(
    (state) => state.clipPage.resultImg
  );
  const [modalActive, setModalActive] = useState<number | null>(null);
  const [transitionToCard, setTransitionToCard] = useState<number | null>(null);
  const [deletingCard, setDeletingCard] = useState<number | null>(null);
  const resultImgRef = useRef(resultImg);

  interface CustomResponse extends Response {
    resultImgUrl: string | null;
    error: string | null;
  }

  useEffect(() => {
    resultImgRef.current = resultImg;
  }, [resultImg]);

  const sendRequest = async (newId) => {
    if (dstImg && srcImg) {
      const response = await fetch(`${userInfoServerBaseUrl}/startSesh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          auth,
          dstImg,
          src: srcImg,
          frontendId: newId,
        }),
      });

      if (response.ok) {
        const res = await response.json();
        const latestResultImg = resultImgRef.current;
        const newImg = [...latestResultImg];
        for (let i = 0; i < newImg.length; i++) {
          if (newImg[i]?.id === newId) {
            if (!res.error) {
              newImg[i] = { ...newImg[i], result: res.resultImgUrl };
              dispatch(updateResultImg(newImg));
              handleOpen(i);
              return;
            } else {
              newImg[i] = { ...newImg[i], error: res.error };
              dispatch(updateResultImg(newImg));
              handleOpen(i);
              return;
            }
          }
        }
      } else {
        const res = await response.text();
        console.error("Couldn't connect to user info server " + res);
      }
    }
  };

  useEffect(() => {
    if (resultImg.length > 0) {
      const newImg = [...resultImg];
      if (!newImg[newImg.length - 1].id) {
        const newId = nanoid();
        newImg[newImg.length - 1] = { ...newImg[newImg.length - 1], id: newId };
        dispatch(updateResultImg(newImg));
        sendRequest(newId);
        setModalActive(newImg.length - 1);
      }
    }
  }, [resultImg]);

  const handleRemoveFromCards = (index: number) => {
    setDeletingCard(index);
    setTimeout(() => {
      const latestResultImg = resultImgRef.current;
      const newImg = [...latestResultImg];
      newImg.splice(index, 1);
      dispatch(updateResultImg(newImg));
      setDeletingCard(null);
    }, 600);
  };

  const handleClose = (index: number) => {
    setTransitionToCard(index);
    setTimeout(() => {
      setModalActive(null);
      setTransitionToCard(null);
      if (resultImg[index].result) {
        handleRemoveFromCards(index);
      }
    }, 300);
  };

  const handleOpen = (index: number) => {
    setTransitionToCard(index);
    setModalActive(index);
    setTransitionToCard(null);
  };

  return ReactDOM.createPortal(
    <Box
      sx={{
        position: "fixed",
        bottom: 10,
        right: 15,
        width: 200,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Modal
        open={modalActive !== null}
        onClose={() => handleClose(modalActive)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
          sx: {
            backdropFilter: "blur(3px)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <Paper
          sx={{
            position: "relative",
            zIndex: 1301,
            maxWidth: "100%",
            overflow: "hidden",
            outline: "none",
            background: "transparent",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              opacity: transitionToCard ? 0 : 100,
              transition: "all 2.5s ease",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              fontSize={22}
              style={{ paddingBottom: "30px", cursor: "pointer" }}
              onClick={() => handleClose(modalActive)}
            />
            <Box
              component="img"
              src={
                resultImg[modalActive]?.result
                  ? resultImg[modalActive]?.result
                  : resultImg[modalActive]?.dstImg
              }
              alt={`result`}
              loading="lazy"
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
                maxHeight: "70vh",
                borderRadius: 1,
                transition: "all 0.5s ease",
                filter: !resultImg[modalActive]?.result ? "blur(10px)" : null,
              }}
            />
            <LoadingOverlay display={!resultImg[modalActive]?.result} />
          </Box>
        </Paper>
      </Modal>
      {resultImg.map((img, index) => (
        <Box
          key={index}
          onClick={() => handleOpen(index)}
          className="mt-1"
          sx={{
            width: 200,
            maxWidth: "15vw",
            height: "auto",
            cursor: "pointer",
            transition: "all 0.5s ease",
            opacity: 0.9,
            transform:
              transitionToCard === index || modalActive === index
                ? "scale(1.15)"
                : deletingCard === index
                ? "scale(0)"
                : "scale(0.9)",
          }}
        >
          <Box
            component="img"
            src={img.result ? img.result : img.dstImg}
            alt={`image-thumbnail-${index}`}
            loading="lazy"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: 1,
              filter: !img.result ? "blur(8px)" : null,
            }}
          />
          <LoadingOverlay display={!img.result} />
        </Box>
      ))}
    </Box>,
    document.body
  );
};

export default ResultModal;

import { configureStore } from "@reduxjs/toolkit";
import clipPageReducer from "./clipPageSlice";
import authReducer, { authCookieMiddleware } from "./authSlice";
import paymentModalReducer from "./paymentModalSlice";
import termsModalReducer from "./termsModalSlice";
export const store = configureStore({
  reducer: {
    clipPage: clipPageReducer,
    auth: authReducer,
    paymentModal: paymentModalReducer,
    termsModal: termsModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authCookieMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Backdrop, Box, Modal } from "@mui/material";
import { DepositSelector } from "./DepositSelector";
import { useState } from "react";
import { DEFAULT_PAYMENT } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export const DepositModal = ({
  modalActive,
  handleClose,
}: {
  modalActive: boolean;
  handleClose: () => void;
}) => {
  const [selectedPrice, setSelectedPrice] = useState(DEFAULT_PAYMENT);

  return (
    <Modal
      open={modalActive}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1301,
          width: "500px",
          maxWidth: "90vw",
          overflow: "hidden",
          outline: "none",
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            fontSize={30}
            style={{ cursor: "pointer", padding: "25px" }}
            onClick={handleClose}
          />
        </Box>
        <DepositSelector
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
          onDeposit={handleClose}
          isModal
        />
      </Box>
    </Modal>
  );
};

import { Box, Button, Paper, Slider, TextField, Typography } from "@mui/material";
import { DARK_THEME } from "../..";
import { AVG_VID_PRICE, MAX_PAYMENT, MIN_PAYMENT } from "../../util";
import TouchableTooltip from "../Core/TouchableTooltip";
import { openPaymentModal } from "../../redux/paymentModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import xfgem from "../../xfgem.png";

export const DepositSelector = ({
  selectedPrice,
  setSelectedPrice,
  onDeposit,
  isModal,
}: {
  selectedPrice: number;
  setSelectedPrice: (price: number) => void;
  onDeposit?: () => void;
  isModal?: boolean;
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
        maxWidth: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        py: 3,
        px: 2,
      }}
    >
      {isModal && (
        <Typography variant="h6" sx={{ fontWeight: 600, mt: 0, mb: 2 }}>
          Select Deposit Amount
        </Typography>
      )}

      <TextField
        value={selectedPrice === 0 ? "" : selectedPrice}
        onChange={(e) => setSelectedPrice(e.target.value ? parseInt(e.target.value) : 0)}
        sx={{
          width: 100,
          textAlign: "center",
        }}
        InputProps={{
          startAdornment: (
            <Box component="img" src={xfgem} sx={{ height: 20, width: 20, mr: 0.5 }} />
          ),
          sx: {
            color: DARK_THEME.lightpurple,
            fontWeight: 600,
            "& input": {
              textAlign: "center",
            },
          },
        }}
      />

      <Slider
        step={1}
        value={selectedPrice}
        onChange={(e, value) => setSelectedPrice(value as number)}
        min={MIN_PAYMENT}
        max={MAX_PAYMENT}
        sx={{
          width: "400px",
          maxWidth: "100%",
          color: DARK_THEME.lightpurple,
          "& .MuiSlider-thumb": {
            backgroundColor: DARK_THEME.lightpurple,
            "&:hover, &.Mui-focusVisible": {
              boxShadow: `0px 0px 0px 8px ${DARK_THEME.lightpurple}33`,
            },
          },
          "& .MuiSlider-rail": {
            backgroundColor: "#ffffff33",
          },
        }}
      />

      <Typography sx={{ fontWeight: 600 }}>
        ${(selectedPrice / 100).toFixed(2)}
      </Typography>

      <Typography sx={{ fontWeight: 600, mt: 1.5, mb: 1 }}>
        ≈{Math.floor(selectedPrice / AVG_VID_PRICE)} videos
      </Typography>
      <TouchableTooltip
        title={
          selectedPrice < MIN_PAYMENT
            ? "Minimum deposit is $2.39"
            : auth?.jwt
            ? ""
            : "Sign in to purchase."
        }
      >
        <Button
          variant="contained"
          size="large"
          sx={{ width: 200, height: 100, borderRadius: 500, fontSize: 16, mt: 2 }}
          onClick={() => {
            if (onDeposit) onDeposit();
            dispatch(openPaymentModal(selectedPrice));
          }}
          disabled={!auth?.jwt}
        >
          MAKE DEPOSIT
        </Button>
      </TouchableTooltip>
    </Paper>
  );
};

import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import LoadingText from "./LoadingText";
import NavBar from "../NavBar";
import { Box, Typography } from "@mui/material";

const SessionCard = ({ session }) => {
  const [showError, setShowError] = useState(null);
  const [aspectRatio, setAspectRatio] = useState("56.25%");

  useEffect(() => {
    if (session.error) {
      setShowError(session.error);
    }
  }, [session]);

  useEffect(() => {
    if (session.preview) {
      const img = new Image();
      img.onload = () => {
        const ratio = (img.height / img.width) * 100;
        setAspectRatio(`${ratio}%`);
      };
      img.src = session.preview;
    }
  }, [session.preview]);

  return (
    <div className="w-full relative" style={{ paddingBottom: aspectRatio }}>
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="w-full h-full relative overflow-hidden">
          {session.preview && (
            <Box
              component="img"
              src={session.preview}
              alt="preview"
              style={{
                filter: `blur(${
                  (100 - parseFloat(session.completionPercentage || 0)) / 7
                }px)`,
                transition: "all 0.5s ease",
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
          <div className="absolute top-0 w-full flex" style={{ gap: "1px" }}>
            <style>{`
            .progress-bar {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              height: 20px;
              width: 100%; /* Full width */
            }
  
            .progress-bar::-webkit-progress-bar {
              background-color: #33333376;
              border-radius: 9px;
            }
  
            .progress-bar::-webkit-progress-value {
              background-color: #6686af;
              border-radius: 8px;
              transition: width 1s ease-in-out;
            }

             .progress-bar::-moz-progress-bar {
              background-color: #6686af;
              border-radius: 8px;
              transition: width 1s ease-in-out;
            }

            .progress-bar {
              background-color: #33333376;
              border-radius: 9px;
            }
        `}</style>
            <progress
              className="progress-bar"
              value={1}
              max={1}
              style={{ width: "7%" }}
            ></progress>
            <progress
              className="progress-bar"
              value={session.emdl1 ? 1 : 0}
              max={1}
              style={{ width: "10%" }}
            ></progress>
            <progress
              className="progress-bar"
              value={session.vmfound2?.queued ? 0.5 : session.vmfound2?.found ? 1 : 0}
              max={1}
              style={{ width: "23%" }}
            ></progress>
            <progress
              className="progress-bar"
              value={(parseFloat(session.completionPercentage) || 0) / 100}
              max={1}
              style={{ width: "60%" }}
            ></progress>
          </div>
          <div className="w-full h-full flex flex-col justify-around p-4 relative z-10 text-center">
            {showError ? (
              <Typography variant="h5" color="darkred" sx={{ pt: 3 }}>
                Error: {showError}
              </Typography>
            ) : (
              <div className="flex flex-col h-full">
                <div className="flex flex-col items-center justify-center mt-4 mb-8 h-full">
                  <div className="mt-12 mb-6">
                    <Oval
                      visible={true}
                      height="45"
                      width="45"
                      color="#6686af"
                      secondaryColor="#6686af"
                      ariaLabel="oval-loading"
                    />
                  </div>
                  <LoadingText loadingObj={session} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;

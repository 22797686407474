import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Paper, Tooltip } from "@mui/material";
import ImageUpload from "./ImageUpload"; // Adjust the import path as necessary
import PresetImageLibrary from "./PresetImageLibrary"; // Adjust the import path as necessary
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { authSliceType } from "../../redux/authSlice";
import UserResultsLibrary from "./UserResultsLibrary";
import TouchableTooltip from "../Core/TouchableTooltip";

const ImageSelectMain = ({ srcOrDst, callback, imgTabSelection, setImgTabSelection }) => {
  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const [imageSelectType, setImageSelectType] = useState("presets");
  const [presetImages, setPresetImages] = useState(null);

  const handleTabChange = (event, newValue) => {
    setImgTabSelection(newValue);
  };

  useEffect(() => {
    if (!auth?.jwt) {
      setImgTabSelection("presets");
    }
  }, [auth, setImgTabSelection]);

  useEffect(() => {
    setImageSelectType(imgTabSelection);
  }, [imgTabSelection]);

  return (
    <Paper
      className="flex flex-col items-center content-center w-full p-2"
      sx={{ minWidth: "320px" }}
    >
      <Box>
        <Tabs
          value={imageSelectType}
          onChange={handleTabChange}
          centered
          scrollButtons={false}
        >
          <Tab label="Presets" value="presets" />
          {auth?.jwt ? (
            <Tab label="New Face" value="upload" disabled={!auth?.jwt} />
          ) : (
            <TouchableTooltip title="Sign in to upload a picture.">
              <span>
                <Tab label="New Face" value="upload" disabled={!auth?.jwt} />
              </span>
            </TouchableTooltip>
          )}
          {auth?.jwt ? (
            <Tab label="Your Uploads" value="yourlibrary" disabled={!auth?.jwt} />
          ) : (
            <TouchableTooltip title="Sign in to view your uploads.">
              <span>
                <Tab label="Your Uploads" value="yourlibrary" disabled={!auth?.jwt} />
              </span>
            </TouchableTooltip>
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {imageSelectType === "upload" && (
          <ImageUpload srcOrDst={srcOrDst} callback={callback} />
        )}
        {imageSelectType === "yourlibrary" && (
          <UserResultsLibrary srcOrDst={srcOrDst} callback={callback} isUploads={true} />
        )}
        {imageSelectType === "presets" && (
          <PresetImageLibrary
            srcOrDst={srcOrDst}
            presetImages={presetImages}
            setPresetImages={setPresetImages}
            imgTabSelection={imgTabSelection}
            callback={callback}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ImageSelectMain;

import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDstImg, updateSrcImg } from "../../redux/clipPageSlice";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageModal from "./ImageModal";

const ImageThumbnail = ({ img, srcOrDst, callback, showName, selectable, isResults }) => {
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const { url, name, dst, src } = img;

  const handleClick = () => {
    if (srcOrDst === "src") {
      dispatch(updateSrcImg(url || img));
      if (callback) callback();
    } else {
      dispatch(updateDstImg(url || img));
      if (callback) callback();
    }
  };

  const handleMouseOver = () => {
    if (!modalActive) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleOpenImageModal = () => {
    setModalActive(true);
    setHovered(false);
  };

  return (
    <Paper
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "8px",
        bgcolor: "background.paper",
        transition: "background-color 0.3s",
        "&:hover": {
          bgcolor: "darkgray",
        },
        width: "100%",
      }}
    >
      <ImageModal
        modalActive={modalActive}
        setModalActive={setModalActive}
        srcOrDst={srcOrDst}
        img={img}
        callback={callback}
        selectable={selectable}
        isResults={isResults ? { dst, src } : null}
      />
      <Box
        component="img"
        onClick={handleOpenImageModal}
        src={hovered && isResults ? dst : url || img}
        alt={name}
        loading="lazy"
        sx={{
          cursor: "pointer",
          width: "100%",
          height: "auto",
          borderRadius: 1,
        }}
      />
      <Box sx={{ height: 36 }}>
        {hovered ? (
          <Box sx={{ display: "flex", mt: 0.7, gap: 0.5 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleOpenImageModal}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={20} />
            </Button>
            {selectable && (
              <Button variant="contained" size="small" onClick={handleClick}>
                Select
              </Button>
            )}
          </Box>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "15px",
              width: "100%",
              wordBreak: "break-word",
              whiteSpace: "normal",
              pt: 1.2,
              pb: 0.5,
            }}
          >
            {showName ? name : "\u00A0"}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ImageThumbnail;

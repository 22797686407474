export const googleOAuthClientId =
  "1051756212324-ruinjo71rs9utlkciei99mlgaltdlh8o.apps.googleusercontent.com";

export const userInfoServerBaseUrl = "https://infoandseshfind-xjbws6a7ca-uc.a.run.app";

export const sessionServerBaseUrl = "https://gpu-finder-public-cu3tv4lx2a-uc.a.run.app";
export const sessionServerNoHttp = "gpu-finder-public-cu3tv4lx2a-uc.a.run.app";
export const testB = "test";

export const API_KEY =
  "pk_live_51Psvs1Fw9TjERAIgEvgbVJn325Ig6Lo7rcR38H0RZUvCO4aeVrDGYdv6Q6uSTK5TQcD5oW33ACWD73prXMnCTwLc00ZbWvzYK5";
export const API_KEY_TEST =
  "pk_test_51Psvs1Fw9TjERAIgL2jhDYJrQQD6Hilq4pBD7DpyUuVcgrr5PUtrtHrdMB83n5KWyrOYC4b2mAnVEvF4nXVTKxCm00KQodZBvg";

import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ImageThumbnail from "./ImageThumbnail";
import { faKiwiBird } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThreeDots } from "react-loader-spinner";

const ImageLibrary = ({
  images,
  srcOrDst,
  callback,
  showName,
  selectable,
  isResults,
  loading,
}) => {
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef(null);
  const [page, setPage] = useState(1);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));

  const getCols = () => {
    if (isSm) return 2;
    if (isMd) return 3;
    if (isLg) return 4;
    if (isXl) return 5;
    return 5;
  };

  const cols = getCols();

  let perPage = cols * 5;
  let minPerPage = 6;
  let maxPerPage = 16;
  if (perPage < minPerPage) perPage = minPerPage;
  if (perPage > maxPerPage) perPage = maxPerPage;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
  }, [images]);

  useEffect(() => {
    if (listRef.current) {
      setTimeout(() => {
        setListHeight(listRef?.current?.clientHeight || 0);
      }, 500);
    }
  }, [page, images]);

  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  if (loading) {
    return (
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#6686af"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    );
  }

  if (!images || images.length <= 0) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FontAwesomeIcon icon={faKiwiBird} />
          <Typography>Nothing here yet!</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        py: 3,
        px: 1,
        width: "100%",
        maxWidth: "1100px",
      }}
    >
      <Box
        ref={listRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ImageList
          variant="masonry"
          cols={cols}
          gap={8}
          sx={{
            width: "100%",
            "& .MuiImageListItem-root": {
              width: "100%",
            },
          }}
        >
          {images &&
            images.slice(startIndex, endIndex).map((img) => (
              <ImageListItem key={img.url}>
                <ImageThumbnail
                  img={img}
                  srcOrDst={srcOrDst}
                  callback={callback}
                  showName={showName}
                  selectable={selectable}
                  isResults={isResults}
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 4,
        }}
      >
        <Button
          variant="contained"
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
          size="large"
        >
          {"< "}Prev
        </Button>
        <Button
          variant="contained"
          disabled={!images || endIndex >= images.length}
          onClick={() => handlePageChange(page + 1)}
          style={{ marginLeft: "1rem" }}
          size="large"
        >
          Next{" >"}
        </Button>
      </Box>
    </Box>
  );
};

export default ImageLibrary;

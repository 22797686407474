import { createSlice } from "@reduxjs/toolkit";

interface TermsModalState {
  show: boolean;
}

const initialState: TermsModalState = {
  show: false,
};

const termsModalSlice = createSlice({
  name: "termsModal",
  initialState,
  reducers: {
    setShowTermsModal: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setShowTermsModal } = termsModalSlice.actions;
export default termsModalSlice.reducer;

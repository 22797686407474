import { Box, Card, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { clipPageSliceType, updateDstImg, updateSrcImg } from "../../redux/clipPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";

const SelectedImage = ({ srcOrDst }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [containerWidth, setContainerWidth] = useState("auto");
  const imageRef = useRef(null);

  const srcImg = useSelector<RootState, string>((state) => state.clipPage.srcImg);
  const dstImg = useSelector<RootState, string>((state) => state.clipPage.dstImg);

  useEffect(() => {
    if (imageRef.current && !isExpanded) {
      setContainerWidth(imageRef.current.offsetWidth);
    } else {
      setContainerWidth("auto");
    }
  }, [isExpanded]);

  const handleRemoveImg = () => {
    if (srcOrDst === "src") {
      dispatch(updateSrcImg(""));
    } else {
      dispatch(updateDstImg(""));
    }
  };

  return (
    <div
      style={{
        width: containerWidth,
        transition: "width 0.3s ease-in-out",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "gray.700",
          p: 0,
        }}
      >
        <Paper
          sx={{ display: "flex", width: "100%", justifyContent: "space-between", p: 1 }}
        >
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ display: "flex", padding: "0.25rem", cursor: "pointer" }}
          >
            <div style={{ transform: isExpanded ? "" : "rotate(-90deg)" }}>
              <KeyboardArrowDownIcon sx={{ scale: "1.2" }} />
            </div>
          </div>
          <div
            onClick={handleRemoveImg}
            style={{ cursor: "pointer", padding: "0.25rem", marginRight: "0.25rem" }}
          >
            <DeleteIcon htmlColor="white" />
          </div>
        </Paper>
        <Box
          ref={imageRef}
          component="img"
          sx={{
            maxHeight: isExpanded ? "40vh" : "0px",
            width: "auto",
            objectFit: "contain",
            transition: "max-height 0.3s ease-in-out",
          }}
          src={srcOrDst === "src" ? srcImg : dstImg}
          alt="source face"
        />
      </Card>
    </div>
  );
};

export default SelectedImage;

import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import "./styles/Home.css";
import { Box, Button, Card, Paper, Slider, TextField, Typography } from "@mui/material";

import UploadCard from "./components/ClipPage/UploadCard";
import { useNavigate } from "react-router-dom";
import xflogo from "./xflogo_500.png";
import xfgem from "./xfgem.png";
import MorphingCircle from "./components/HomePage/MorphingCircle";

import { useDispatch, useSelector } from "react-redux";
import { access } from "fs/promises";
import NavBar from "./components/NavBar";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResultModal from "./components/ClipPage/ResultModal";
import Footer from "./components/Footer";
import { DARK_THEME } from ".";
import PaymentAdvertisement from "./components/HomePage/PaymentAdvertisement";
import { AVG_VID_PRICE, DEFAULT_PAYMENT, MAX_PAYMENT, MIN_PAYMENT } from "./util";
import { openPaymentModal } from "./redux/paymentModalSlice";
import TouchableTooltip from "./components/Core/TouchableTooltip";
import { RootState } from "./redux/store";
import {
  updateBalance,
  updateBucketName,
  updateJwt,
  updateEmail,
  updatePicture,
  updateSubId,
} from "./redux/authSlice";
import { userInfoServerBaseUrl } from "./env";
import { GoogleLogin } from "@react-oauth/google";
import { DepositSelector } from "./components/PaymentModal/DepositSelector";

function Home() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [videoHeight, setVideoHeight] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(DEFAULT_PAYMENT);
  const [signedUpOrIn, setSignedUpOrIn] = useState<string | null>(null);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (videoRef.current) {
      setVideoHeight(videoRef?.current?.clientHeight || 0);
    }
  }, []);

  const signinInProgressRef = useRef(false);

  const onLoginSuccess = useCallback(
    (credentialResponse: any) => {
      signinInProgressRef.current = true;
      const idToken = credentialResponse.credential; // Get the ID Token

      fetch(`${userInfoServerBaseUrl}/loginGoogle`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ idToken }),
      })
        .then((response) => response.json()) // Get JWT from response
        .then((data) => {
          if (!data.error) {
            dispatch(updateJwt(data.jwt));
            dispatch(updateEmail(data.email));
            dispatch(updatePicture(data.pictureUrl));
            dispatch(updateSubId(data.subId));
            dispatch(updateBucketName(data.bucketName));
            dispatch(updateBalance(data.balance));
            if (data.signedUp === true) {
              setSignedUpOrIn("up");
            } else {
              setSignedUpOrIn("in");
            }
          } else {
            throw Error(`server returned error obj: ${data.error}`);
          }
        })
        .catch((error) => {
          console.error("Error calling google login:", error);
        });
      signinInProgressRef.current = false;
    },
    [dispatch]
  );

  const [selectedAlignment, setSelectedAlignment] = useState("youtube");
  const handleAlignmentChange = (newAlignment) => {
    setSelectedAlignment(newAlignment);
  };

  const navToClip = () => {
    if (!signinInProgressRef.current) {
      navigate("/clip");
      return;
    }

    const checkAndNavigate = () => {
      if (!signinInProgressRef.current) {
        navigate("/clip");
        clearInterval(intervalId); // Stop interval when done
      }
    };

    const intervalId = setInterval(checkAndNavigate, 100);
  };

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setScrolledDown(true);
      }

      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const videoSource =
    "https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/bgvid-v3.mp4?alt=media&token=d05f0155-261a-4257-b50e-e5e8a8ac59bd";

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "relative",
          top: 0,
          width: "100%",
          overflow: "hidden",
          zIndex: 888,
        }}
      >
        <NavBar
          signinInProgressRef={signinInProgressRef}
          signedUpOrInProp={signedUpOrIn}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "130vh",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(to bottom, transparent 50%, #121212 100%)",
            zIndex: "0",
          }}
        />
        <video
          autoPlay
          loop
          muted
          ref={videoRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "auto",
            zIndex: "-1",
          }}
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: "1",
          top: "0",
          width: "100%",
          margin: "0 auto",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <div className="flex flex-col items-center" style={{ marginTop: "15vh" }}>
          <img
            src={xflogo}
            alt="clip fake logo"
            style={{
              maxWidth: "20vw",
              imageRendering: "crisp-edges",
              filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
            }}
          />
          <Typography
            variant="h1"
            className="text-[#6686af]"
            style={{
              marginTop: 16,
              filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
              fontWeight: 600,
              fontSize: 45,
            }}
          >
            ClipFake
          </Typography>
        </div>
        <div
          className={`flex flex-col items-center ${
            selectedAlignment === "youtube" ? "mb-[10vh] lg:mb-[12vh]" : "mb-[10vh]"
          }`}
          style={{
            marginTop: "10vh",
            marginLeft: "4px",
            filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
          }}
        >
          <UploadCard
            ind={0}
            callback={navToClip}
            handleAlignmentChange={handleAlignmentChange}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            mb: 5,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: { lg: "65%", xs: "100%" },
              background: "radial-gradient(circle at top center, #1e1e1e, #121212 60%)",
              borderRadius: 3,
            }}
          >
            <Typography variant="h5" sx={{ mt: 3, mb: 5 }}>
              Deep fake directly from YouTube
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: { lg: "60%", xs: "90%" },
                  maxWidth: "800px",
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/clippingaction_v4.jpg?alt=media&token=4ab7fef9-e431-4414-9d6a-0dd7dde36931"
                  alt="clipping action"
                />
                <Box sx={{ mt: 1 }}>Clip only the desired portion of the video</Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                  mt: 5,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: { lg: "80%", xs: "60%", borderRadius: 5 },
                    maxWidth: "800px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/ramsay.jpg?alt=media&token=718857b0-56f0-404b-95f6-d225a664206e"
                  alt="example face"
                />
                <Box sx={{ mt: 1 }}>Overlay a selected face onto the clip</Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                mt: 10,
                mb: 2,
              }}
            >
              <Box component="img" src={xfgem} sx={{ height: 25, width: 25, mr: 2 }} />
              <Typography variant="h5">Pay-as-you-go pricing</Typography>
              <Box component="img" src={xfgem} sx={{ height: 25, width: 25, ml: 2 }} />
            </Box>
            <Typography variant="h6">
              No more bogus subscriptions. No more "free" trials that charge later -
            </Typography>
            <Typography variant="h6">
              Get started deepfaking YouTube videos for cheap, right now!
            </Typography>
            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "center", maxWidth: "95vw" }}
            >
              <DepositSelector
                selectedPrice={selectedPrice}
                setSelectedPrice={setSelectedPrice}
              />
            </Box>
            {!auth?.jwt && (
              <Box sx={{ mt: 5, maxWidth: "250px" }}>
                <GoogleLogin
                  onSuccess={(credentialResponse: any) =>
                    onLoginSuccess(credentialResponse)
                  }
                  onError={() => {
                    console.error("Login Failed");
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Footer />
      </div>
    </div>
  );
}

export default Home;

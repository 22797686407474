import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { authSliceType, logout, updateBalance } from "./redux/authSlice";
import NavBar from "./components/NavBar";
import { ThreeDots } from "react-loader-spinner";
import { userInfoServerBaseUrl } from "./env";
import { useNavigate } from "react-router-dom";

const MyProfilePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const getProfile = async () => {
    let response = null;
    try {
      response = await fetch(`${userInfoServerBaseUrl}/myProfile`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          auth,
        }),
      });
    } catch (error) {
      setLoading(false);
      console.error("Fetch error: ", error);
      return;
    }

    if (!response) {
      setLoading(false);
      console.error("Error: response not received");
      return;
    }

    let resObj = await response.json();

    if (resObj.error) {
      setLoading(false);
      console.error(`Response was an error :: ${resObj.error}`);
      return;
    } else if (resObj) {
      setData(resObj);
      dispatch(updateBalance(resObj.balance));
    }

    setLoading(false);
  };

  const deleteAccount = async () => {
    let response = null;
    try {
      response = await fetch(`${userInfoServerBaseUrl}/deleteAccount`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          auth,
        }),
      });
    } catch (error) {
      console.error("Fetch error: ", error);
      return;
    }

    if (!response) {
      console.error("Error: response not received");
      return;
    }

    let resObj = await response.json();

    if (resObj.error) {
      console.error(`Response was an error :: ${resObj.error}`);
      return;
    } else if (resObj.success) {
      dispatch(logout());
      navigate("/");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleDeleteProfile = () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete your profile? This will delete all associated information and nullify any remaining balance!"
    );
    if (confirmed) {
      deleteAccount();
    } else {
      return;
    }
  };

  if (!auth?.jwt) {
    return (
      <div className="flex flex-col items-center content-center w-full">
        <NavBar signinInProgressRef={null} />
        Sign in to view this page.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center content-center">
        <NavBar signinInProgressRef={null} />
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#6686af"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <Box>
      <NavBar signinInProgressRef={null} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={auth.picture}
          className="rounded-full"
          style={{ maxHeight: "90px", maxWidth: "90px", marginTop: "100px" }}
          alt="profpic"
        />
        <Typography variant="h5" sx={{ mt: 1, fontWeight: "bold" }}>
          {auth.email}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 7, mt: 12 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {data?.numUploads}
            </Typography>
            <Typography>{data?.numUploads === 1 ? "Upload" : "Uploads"}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {data?.numResultImages}
            </Typography>
            <Typography>
              {data?.numResultImages === 1 ? "Image Fake" : "Image Fakes"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {data?.numPastSessions}
            </Typography>
            <Typography>
              {data?.numPastSessions === 1 ? "Clip Fake" : "Clip Fakes"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 40 }}>
          <Button color="error" variant="contained" onClick={handleDeleteProfile}>
            Delete Profile
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProfilePage;

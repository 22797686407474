export const formatDateTime = (dateparam: Date) => {
  const date = new Date(dateparam);
  // Extract values
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  // 12-hour format conversion
  hours = hours % 12;
  hours = hours ? hours : 12; // 12 instead of 0

  // Zero padding for minutes
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Get date parts
  const month = date.getMonth() + 1; // Months are 0-based
  const day = date.getDate();
  const year = date.getFullYear();

  // Construct formatted time string
  const formattedDateTime = `${hours}:${minutes}${ampm}, ${month}/${day}/${year}`;

  return formattedDateTime;
};

export function formatDuration(seconds: number): String {
  if (seconds === 0) return "00:00";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  const formattedHours = hours > 0 ? hours.toString().padStart(2, "0") + ":" : "";
  const formattedMinutes =
    hours > 0 ? `${minutes.toString().padStart(2, "0")}:` : `${minutes.toString()}:`;
  const formattedSeconds = secondsLeft.toString().padStart(2, "0");

  return formattedHours + formattedMinutes + formattedSeconds;
}

export function validateYouTubeUrl(str: String): string {
  var url = str;
  if (url !== undefined || url !== "") {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url?.match(regExp);
    if (match)
      if (match && match[2].length == 11) {
        // For now valid Youtube video IDs are 11 chars long
        const vidId = match[2];
        return vidId;
      }
  }
  return "";
}

export const VIDEO_MIN_PRICE = 20;
export const PRICE_PER_SECOND = 0.2;
export const IMG_PRICE = 2;
export const MIN_VID_LENGTH = 5;
export const MAX_VID_LENGTH = 120;
export const QUALITY_MULTIPLIERS = { "480p": 0.9, "720p": 1, "1080p": 1.2 };

export const MIN_PAYMENT = 239;
export const MAX_PAYMENT = 1999;
export const DEFAULT_PAYMENT = 499;
export const AVG_VID_PRICE = 23;

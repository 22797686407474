import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Box, Modal } from "@mui/material";
import React from "react";

const ImageOrInfoModal = ({ modalActive, handleClose, component }) => {
  return (
    <Modal
      open={modalActive}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1301,
          maxWidth: "90vw",
          maxHeight: "100vh",
          overflow: "hidden",
          outline: "none",
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            paddingBottom: "5px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            fontSize={30}
            style={{ cursor: "pointer", padding: "25px" }}
            onClick={handleClose}
          />
        </Box>
        {component}
      </Box>
    </Modal>
  );
};

export default ImageOrInfoModal;

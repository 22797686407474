import {
  Backdrop,
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { togglePaymentModal, closePaymentModal } from "../../redux/paymentModalSlice";
import { loadStripe } from "@stripe/stripe-js";
import { API_KEY, API_KEY_TEST, userInfoServerBaseUrl } from "../../env";
import { authSliceType, fetchRemoteBalance } from "../../redux/authSlice";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export const PaymentModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector<RootState, authSliceType>((state) => state.auth);
  const { show, paymentAmount } = useSelector((state: RootState) => state.paymentModal);

  const [stripePromise, setStripePromise] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchClientSecret = useCallback(async () => {
    setError(null);
    return fetch(`${userInfoServerBaseUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: paymentAmount,
        auth: auth,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        return data.clientSecret;
      })
      .catch((err) => {
        console.error("Error fetching client secret:", err);
        setError("Failed to initialize checkout. Please try again.");
        throw err;
      })
      .finally(() => null);
  }, [auth, paymentAmount]);

  const handleCheckoutComplete = useCallback(async () => {
    setTimeout(() => {
      dispatch(fetchRemoteBalance());
    }, 3000);
  }, [dispatch]);

  const options = { fetchClientSecret, onComplete: handleCheckoutComplete };

  useEffect(() => {
    if (show && !stripePromise) {
      setStripePromise(loadStripe(API_KEY));
    }
  }, [show, stripePromise]);

  const handleClose = () => {
    dispatch(closePaymentModal());
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "100vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            zIndex: 1302,
          }}
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon={faCircleXmark}
            fontSize={20}
            style={{ cursor: "pointer", padding: "10px" }}
            onClick={handleClose}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1301,
            width: "500px",
            maxWidth: "90vw",
            overflowY: "auto",
            outline: "none",
            background: "white",
            padding: 3,
            borderRadius: 2,
          }}
        >
          {error && <Typography>{error}</Typography>}
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout className={"max-h-[90vh]"} />
          </EmbeddedCheckoutProvider>
        </Box>
      </Box>
    </Modal>
  );
};

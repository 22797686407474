import { Box } from "@mui/material";

import { Tooltip } from "@mui/material";
import { useState } from "react";

const TouchableTooltip = ({
  title,
  children,
  disabled,
}: {
  title: string;
  children: any;
  disabled?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);
  };

  if (disabled || !title) {
    return children;
  }

  return (
    <Tooltip
      arrow
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      title={title}
      PopperProps={{
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -5],
            },
          },
        ],
      }}
    >
      <Box onClick={handleClick}>{children}</Box>
    </Tooltip>
  );
};

export default TouchableTooltip;

import React, { useContext, useEffect, useRef, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import {
  Button,
  Card,
  Paper,
  Slider,
  SliderThumb,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Fab,
  ButtonGroup,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { debounce, throttle } from "lodash";
import { Forward10, Replay10 } from "@mui/icons-material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";
import { sliderThumbStyle } from "../../styles/sliderThumbStyle";
import { Range } from "react-range";
import watermelonSlice from "../../watermelon-slice.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  clipPageSliceType,
  updateDstTimeArrByInd,
  updateDstYoutubeStringsByInd,
} from "../../redux/clipPageSlice";
import { formatDuration, MIN_VID_LENGTH } from "../../util";
import { RootState } from "../../redux/store";
import { DARK_THEME } from "../..";
import TouchableTooltip from "../Core/TouchableTooltip";

const ClipCard = ({ cropObj, ind, maxLength, handleQualitySelect, quality }) => {
  const dispatch = useDispatch();

  // Slider states & constants
  const [vidInfo, setVidInfo] = useState(null);
  const [vidDuration, setVidDuration] = useState(300);

  const sliderVals = useSelector<RootState, any>(
    (state) => state.clipPage.dstCropArr[0].timeArr
  );

  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);
  const playerRef = useRef<any>(null);

  // Get video's title via oembed
  useEffect(() => {
    async function getInf() {
      const response = await fetch(
        `https://www.youtube.com/oembed?url=${cropObj.fullYoutube}`
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Error fetching oEmbed data :(");
      }
    }
    if (!cropObj.fullYoutube) return;
    getInf()
      .then((videoInfo) => {
        setVidInfo(videoInfo);
      })
      .catch((error) => console.error(error));
    setVidInfo(getInf());
  }, [cropObj.fullYoutube]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        if (
          playerRef.current.getPlayerState() === 1 &&
          Math.floor(currentTime) !== sliderVals[1]
        ) {
          dispatch(
            updateDstTimeArrByInd({
              ind,
              arr: [sliderVals[0], Number.parseInt(currentTime), sliderVals[2]],
            })
          );
        }
        if (currentTime >= sliderVals[2] || currentTime < sliderVals[0]) {
          // playerRef.current.pauseVideo();
          playerRef.current.seekTo(sliderVals[0]); // reset player
        }
      }
    }, 300); // Check for out of time bounds every 100ms
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [sliderVals, dispatch, ind]);

  useEffect(() => {
    if (isMuted) {
      playerRef.current?.mute();
    } else {
      playerRef.current?.unMute();
    }
  }, [isMuted]);

  useEffect(() => {
    if (playerLoaded) {
      if (isPaused) {
        playerRef.current?.pauseVideo();
      } else {
        playerRef.current?.playVideo();
      }
    }
  }, [isPaused, playerLoaded]);

  // Maintain accuracy of pause/unmute toggle btn pos after collapse
  useEffect(() => {
    if (isExpanded) {
      setIsPaused(false);
      setIsMuted(true);
    }
  }, [isExpanded]);

  const handleSliderChange = (newValues) => {
    let [newStart, newEnd] = newValues;
    let [oldStart, oldEnd] = sliderVals;

    let activeThumb;
    if (newStart !== oldStart) {
      activeThumb = 0; // Start thumb
    } else if (newEnd !== oldEnd) {
      activeThumb = 2; // End thumb
    } else {
      activeThumb = 1; // Current position thumb
    }

    if (newEnd - newStart < MIN_VID_LENGTH) {
      // If one thumb needs to "push" the other because of MIN_VID_LENGTH
      if (activeThumb === 0) {
        const clamped = Math.min(newStart, vidDuration - MIN_VID_LENGTH);
        newValues = [clamped, sliderVals[1], clamped + MIN_VID_LENGTH];
      } else if (activeThumb === 2) {
        const clamped = Math.max(newEnd, MIN_VID_LENGTH);
        newValues = [clamped - MIN_VID_LENGTH, sliderVals[1], clamped];
      }
    } else {
      // If one thumb needs to "pull" the other because of maxLength,
      // or if the middle thumb is being dragged
      if (activeThumb === 0) {
        const clamped = Math.min(newEnd, newStart + maxLength);
        newValues = [newStart, sliderVals[1], clamped];
      } else if (activeThumb === 2) {
        const clamped = Math.max(newStart, newEnd - maxLength);
        newValues = [clamped, sliderVals[1], newEnd];
      }
    }

    if (newValues.length < 3) {
      newValues = [newValues[0], sliderVals[1], newValues[1]];
    }
    dispatch(updateDstTimeArrByInd({ ind, arr: newValues }));

    // If the middle thumb (current position) is moved, seek the video
    if (activeThumb === 1 && playerRef.current) {
      // playerRef.current.seekTo(newCurrent);
    }
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    playerRef.current = event.target;
    const player = event.target;
    setPlayerLoaded(true);
    // player.pauseVideo();
    player.mute();
    dispatch(
      updateDstTimeArrByInd({ ind, arr: [0, 1, Math.min(20, player.getDuration())] })
    );
    setVidDuration(player.getDuration());
  };

  const opts: YouTubeProps["opts"] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      width: "100%",
      height: "100%",
    },
  };

  const handlePlayPause = () => {
    setIsPaused(!isPaused);
  };

  const handleMuteUnmute = () => {
    setIsMuted(!isMuted);
  };

  const handleForwardTen = () => {
    const currTime = playerRef.current?.getCurrentTime();
    playerRef.current?.seekTo(currTime + 10);
  };

  const handleBackTen = () => {
    const currTime = playerRef.current?.getCurrentTime();
    playerRef.current?.seekTo(currTime - 10);
  };

  const handleRemoveVideo = () => {
    dispatch(
      updateDstYoutubeStringsByInd({
        ind: ind,
        fullYoutube: "",
        youtubeId: "",
      })
    );
  };

  const handleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setPlayerLoaded(false);
    }
  };

  return (
    <Box className="flex flex-col items-center px-2 pb-2" sx={{ minWidth: "80vw" }}>
      <Card className="flex flex-col items-center w-full md:w-4/5  bg-gray-700 p-0">
        <Paper className="flex w-full justify-between p-1">
          <div onClick={() => null} className="flex p-1 cursor-pointer">
            {/* <div className={isExpanded ? "" : "-rotate-90 -translate-x-1 -translate-y-1"}>
              <KeyboardArrowDownIcon className="ml-1 mr-3" sx={{ scale: "1.2" }} />
            </div> */}
            <Typography>{vidInfo?.title}</Typography>
          </div>
          <div onClick={handleRemoveVideo} className="cursor-pointer p-1 mr-1">
            <DeleteIcon htmlColor="white" />
          </div>
        </Paper>

        {isExpanded ? (
          <div className="flex flex-col mb-6 mt-4 w-full max-w-[640px] px-4">
            <div className="relative pt-[56.25%]">
              {" "}
              {/* 16:9 aspect ratio */}
              {cropObj.youtubeId ? (
                <div className="absolute top-0 left-0 w-full h-full">
                  <YouTube
                    videoId={cropObj.youtubeId}
                    opts={{
                      ...opts,
                      width: "100%",
                      height: "100%",
                    }}
                    onReady={onPlayerReady}
                    className="w-full h-full"
                  />
                </div>
              ) : (
                "badUrl"
              )}
            </div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                variant={isMuted ? "contained" : "outlined"}
                onClick={handleMuteUnmute}
                sx={{
                  width: "5px",
                  borderRadius: 500,
                  mb: 1,
                  zIndex: 100,
                }}
              >
                {isMuted ? (
                  <VolumeOffIcon sx={{ position: "relative", height: "20px" }} />
                ) : (
                  <VolumeUpIcon sx={{ position: "relative", height: "20px" }} />
                )}
              </Button>
              <div className="w-full flex items-center justify-center">
                <ButtonGroup size="large" sx={{ zIndex: 1100 }}>
                  <Button onClick={handleBackTen}>
                    <Replay10 />
                  </Button>
                  <Button
                    variant={isPaused ? "contained" : "outlined"}
                    sx={{ zIndex: 1100 }}
                    onClick={handlePlayPause}
                  >
                    {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
                  </Button>
                  <Button onClick={handleForwardTen}>
                    <Forward10 />
                  </Button>
                </ButtonGroup>
              </div>
            </Box>
          </div>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            px: 3,
            mt: 4,
            mb: 4,
            width: "100%",
          }}
        >
          <Range
            step={1}
            min={0}
            max={vidDuration}
            values={[
              Math.min(sliderVals[0], vidDuration),
              Math.min(sliderVals[2], vidDuration),
            ]}
            onChange={handleSliderChange}
            allowOverlap={true}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "100%",
                  backgroundColor: "#ccc",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "6px",
                    backgroundColor: DARK_THEME.blue,
                    left: `${(sliderVals[0] / vidDuration) * 100}%`,
                    width: `${((sliderVals[2] - sliderVals[0]) / vidDuration) * 100}%`,
                  }}
                />
                <div
                  draggable={false}
                  style={{
                    position: "absolute",
                    height: "14px",
                    width: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "100%",
                    backgroundColor: DARK_THEME.blue,
                    left: `calc(${(sliderVals[1] / vidDuration) * 100}% - 7px)`,
                    top: "-3px",
                    pointerEvents: "none",
                  }}
                />
                {children}
              </div>
            )}
            renderThumb={({ props, isDragged, index }) => {
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "24px",
                    width: "24px",
                    marginTop: "8px",
                    backgroundColor: "transparent",
                    backgroundImage: `url(${watermelonSlice})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontSize: "12px", marginTop: "25px" }}>
                    {formatDuration(props.key === 0 ? sliderVals[0] : sliderVals[2])}
                  </div>
                </div>
              );
            }}
          />
        </Box>
        <TouchableTooltip title="Output quality (Affects price)">
          <ToggleButtonGroup
            value={quality}
            sx={{ mt: 3 }}
            size="small"
            exclusive
            onChange={handleQualitySelect}
          >
            <ToggleButton value="480p">480p</ToggleButton>
            <ToggleButton value="720p">720p</ToggleButton>
            <ToggleButton value="1080p">1080p</ToggleButton>
          </ToggleButtonGroup>
        </TouchableTooltip>
        <Box sx={{ mt: 3 }} />
      </Card>
    </Box>
  );
};

export default ClipCard;
